import React from 'react';
import { Layout } from '../components/common';

const ProcessPage = () => {
  return (
    <Layout
      seo={{
        title: 'Impresszum',
        lang: 'hu'
      }}
      baseRoute="/impresszum"
    >
      <section className="p-1 bg-snow">
        <div className="flex my-10 py-12 md:py-24 justify-center">
          <div className="w-full lg:w-1/2">
            <h1 className="text-center my-10">Impresszum</h1>
            <h3 className="mb-10">Tulajdonos és kiadó:</h3>
            <h4 className="font-black mb-2">
              Nemzeti Kutatási, Fejlesztési és Innovációs Hivatal
            </h4>
            <div className="text-lightgrey mb-10">
              <p className="py-1">Cím: 1077 Bp., Kéthly Anna tér 1.</p>
              <p className="py-1">Levelezési cím: 1438 Budapest, Pf. 438.</p>
              <p className="py-1">Telefon: (06 1) 795 9500</p>
              <p className="py-1">
                E-mail:{' '}
                <a
                  className="underline text-secondary"
                  href="mailto:nkfihivatal@nkfih.gov.hu"
                >
                  nkfihivatal@nkfih.gov.hu
                </a>
              </p>
            </div>

            <h4 className="font-black mb-2">A kiadásért felelős:</h4>
            <p className="text-lightgrey">Kun Zsuzsanna, elnök</p>

            <h4 className="font-black mb-2 mt-10">Webmester</h4>
            <p className="text-lightgrey">Majtán Máté</p>

            <h4 className="font-black mb-2 mt-10">
              Programozás, szerkezet és karbantartás:
            </h4>
            <p className="text-lightgrey">Cogito Technologies Kft.</p>
            <p className="text-lightgrey">
              E-mail:{' '}
              <a
                className="underline text-secondary"
                href="mailto:contact@cogito.study"
              >
                contact@cogito.study
              </a>
            </p>

            <h4 className="font-black mb-2 mt-10">Arculat:</h4>
            <p className="text-lightgrey">Cogito Technologies Kft.</p>
            <p className="text-lightgrey">
              E-mail:{' '}
              <a
                className="underline text-secondary"
                href="mailto:contact@cogito.study"
              >
                contact@cogito.study
              </a>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProcessPage;
